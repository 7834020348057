import Network from '@/services/network';

export default function handleBudgets() {
    const network = new Network;

    const createNewBudget = (data, query) => {
        return network.api('post', `/procurement/budget${query}`, data);
    }

    const updateBudget = (id, data, query) => {
        return network.api('put', `/procurement/budget/${id + query}`, data);
    }

    const fetchAccountTree = (id, query) => {
        return network.api('get', `/procurement/budget/accounts/${id + query}`);
    }

    const fetchBudget = (id, query) => {
        return network.api('get', `/procurement/budget/${id + query}`);
    }

    const fetchBudgetGeneral = (budgetId, id, query) => {
        return network.api('get', `/procurement/budget/${budgetId}/general/${id + query}`);
    }

    const fetchAccount = (budgetId, id, query) => {
        return network.api('get', `/procurement/budget/${budgetId}/account/${id + query}`);
    }

    const storeBudgetGeneral = (id, data, query) => {
        return network.api('post', `/procurement/budget/${id}/general${query}`, data);
    }

    const updateBudgetGeneral = (id, generalId, data, query) => {
        return network.api('post', `/procurement/budget/${id}/general/${generalId + query}`, data);
    }

    const fetchBudgetMasters = (query) => {
        return network.api('get', `/procurement/budget${query}`);
    }

    const fetchBudgetVsActual = (budgetMaster, query) => {
        return network.api('get', `/procurement/budget/get/budget-vs-actual/${budgetMaster}${query}`);
    }

    const fetchDonorBudgetVsActual = (projectMaster, query) => {
        return network.api('get', `/procurement/budget/get/donor-bva/${projectMaster}${query}`);
    }

    const fetchNewBudgetInterval = (budgetMaster, query) => {
        return network.api('get', `/procurement/budget/get/interval/${budgetMaster}${query}`);
    }

    return {
        createNewBudget,
        updateBudget,
        fetchAccountTree,
        fetchBudget,
        fetchBudgetGeneral,
        fetchAccount,
        storeBudgetGeneral,
        updateBudgetGeneral,
        fetchBudgetMasters,
        fetchBudgetVsActual,
        fetchDonorBudgetVsActual,
        fetchNewBudgetInterval,
    }
}