<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Budget Name</th>
          <th>Budget Code</th>
          <th>Project Name</th>
          <th>Business Line</th>
          <th>Period</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in budgetList.data">
          <td>{{ item.name }}</td>
          <td>{{ item.code }}</td>
          <td>{{ item.project_master?.name }}</td>
          <td>{{ item.business?.name }}</td>
          <td>{{ item.start_date }} - {{ item.end_date }}</td>
          <td>{{ item.status }}</td>
          <td>
            <span @click="handleEdit(item.id)" style="cursor: pointer">
              Edit
            </span>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!budgetList?.data?.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const {budgetList} = defineProps(['budgetList']);
const router = useRouter()
const route = useRoute()

function handleEdit(budgetId) {
  router.push({
    name: 'project-budget-edit',
    params: {
      ...route.params,
      budgetId
    },
    query: route.query
  });
}
</script>
