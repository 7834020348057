<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="true" title="Budget List"
                   @onClickAddNewButton="onClickAddNewButton"/>
      <div v-if="from_page != 'register'" class="row match-height mt-1">
        <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
          <DateQuerySetter @onClickGo="getBudgets"/>
        </div>
      </div>
    </div>

    <ListTable
        :budgetList="budgetList"
    />
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  from_page: {
    type: String,
    default: ''
  }
})

import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/budget/ListingTable'
import Pagination from '@/components/atom/Pagination'
import {useRoute, useRouter} from "vue-router";
import {computed, inject, onMounted, ref, watch} from "vue";
import handleBudgets from "@/services/modules/procurement/budget";

const showSuccess = inject('showSuccess');
const showError = inject('showError');
const router = useRouter()
const route = useRoute()
const offset = ref(20);
const {fetchBudgetMasters} = handleBudgets();
let page = ref(1);
let loading = ref(false);
let budgetList = ref([]);
let companyId = computed(() => route.params.companyId);

function onClickAddNewButton() {
  router.push({name: 'project-budget-add', params: route.params, query: route.query})
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  if (page.value) query += '&page=' + page.value
  if (route.query.projectId) query += '&project_id=' + route.query.projectId
  return query
}

async function getBudgets() {
  loading.value = true;
  try {
    const res = await fetchBudgetMasters(getQuery());
    if (!res.status) {
      return budgetList.value = [];
    }
    budgetList.value = res.data;
  } catch (err) {
    if (err.response) {
      showError(err.response.message);
    }
  } finally {
    loading.value = false;
  }
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getBudgets();
  }, 100)
}

watch( () => router.currentRoute.value.query.projectId, async (newProjectId) => {
  await getBudgets()
})


onMounted(async () => {
  await getBudgets();
})
</script>
